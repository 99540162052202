import { myApi, myPubApi } from '../utils/apiHelper';

class ProductService {
  async getProducts(queryParam) {
    try {
      return await myPubApi.get(`/getProduct`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getProductsWithPagnation(queryParam) {
    try {
      return await myApi.get(`/getProduct/pagnation`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getProductById(id, data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/productById/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async updateProduct(id) {
    try {
      return await myApi.put(`/updateProduct/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }
}

const productService = new ProductService();
export default productService;
export { productService };

import { Card } from '@mui/material';
import React from 'react';
import { ChevronLeftRounded } from '@mui/icons-material';
import MynyfyText from './MynyfyText';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchBottomTab } from '../redux/actions/bottomTab.action';

const MynyfyHeader = ({
  id,
  title,
  logo,
  customElememt,
  customBack,
  noBack,
  goHome,
  titleStyles,
  headerStyle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();

  return (
    <div id='MynyfyHeader'>
      <Card className='rowSB mynyfyHeader' id={id} style={{ ...headerStyle }}>
        <div className='row'>
          {noBack ? null : (
            <div
              style={{ padding: 5, cursor: 'pointer' }}
              onClick={() => {
                if (goHome) {
                  dispatch(fetchBottomTab('home'));
                }
                if (customBack) {
                  customBack();
                } else {
                  if (
                    window?.history?.state?.idx == 0 ||
                    (window?.history?.length < 3 && !route?.pathname?.includes('/Product/'))
                  ) {
                    navigate('/');
                  } else {
                    navigate(-1);
                  }
                }
              }}>
              <ChevronLeftRounded style={{ width: 30, height: 30, display: 'block' }} />
            </div>
          )}
          <div className='row'>
            {logo ? logo() : null}
            <MynyfyText
              title={title || ''}
              style={{ textTransform: 'capitalize', padding: '0px 5px', ...titleStyles }}
              bold
              h5
            />
          </div>
        </div>
        {customElememt ? <div style={{ padding: '0px 5px' }}>{customElememt()}</div> : null}
      </Card>
    </div>
  );
};

export default MynyfyHeader;

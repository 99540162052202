import { persistReducer, persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { configureStore } from '@reduxjs/toolkit';

const middleware = [];

const sagaMiddleware = createSagaMiddleware();

const loggerMiddleware = createLogger({
  predicate: () => process.env.REACT_APP_ENV === 'development',
});

middleware.push(sagaMiddleware, loggerMiddleware);

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['location', 'mynyfyStats', 'appData', 'user', 'mynts', 'flashAds', 'bag'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({ reducer: persistedReducer, middleware: middleware });

const persistor = persistStore(store);

export { store, persistor };

sagaMiddleware.run(rootSaga);
